import { twJoin } from '@troon/tailwind-preset/merge';
import { Avatar, Tag } from '@troon/ui';
import { Switch, Match, Show } from 'solid-js';
import { IconUserCheck } from '@troon/icons/user-check';
import { TroonCardSubscriptionProductType, ReservationUserState } from '../../../../graphql';
import type { ReservationUser } from '../../../../graphql';
import type { Component } from 'solid-js';

type Props = {
	hasGuestPass?: boolean;
	isHost?: boolean;
	user: ReservationUser;
	userSlot?: number;
};

export function Player(props: Props) {
	return (
		<div class="flex items-center gap-x-4">
			<Avatar
				class={twJoin(
					'size-12 shrink-0 grow-0 rounded-full',
					props.user.user ? 'bg-brand text-brand-100' : 'bg-neutral-700 text-neutral',
				)}
				firstName={props.user.user?.firstName}
				lastName={props.user.user?.lastName}
			/>
			<div class="flex shrink flex-col">
				<span class="flex flex-wrap gap-2">
					<Show when={props.user.user} fallback={<>Player {props.userSlot}</>}>
						{(user) => (
							<span class="truncate">
								{user().firstName} {user().lastName}
							</span>
						)}
					</Show>
					<Show when={props.hasGuestPass || props.user.guestPass}>
						<Tag appearance="access">Guest Pass</Tag>
					</Show>
					<Show when={props.user.user?.troonAccessProductType}>
						{(type) => (
							<Tag appearance="access">{`Access${type() === TroonCardSubscriptionProductType.TroonAccessPlus ? '+' : ''}`}</Tag>
						)}
					</Show>
				</span>
				<Switch>
					<Match when={props.hasGuestPass}>
						<span class="text-sm">Player must join reservation to use Guest Pass</span>
					</Match>
					<Match when={props.isHost}>
						<span class="text-sm">Host</span>
					</Match>
					<Match when={reservationStateToText[props.user.state]}>
						{(content) => <span class="text-sm">{content()({})}</span>}
					</Match>
				</Switch>
			</div>
		</div>
	);
}

const reservationStateToText: Record<ReservationUserState, Component | undefined> = {
	[ReservationUserState.Invited]: () => 'Invited',
	[ReservationUserState.Accepted]: () => (
		<span class="flex items-center text-green-600">
			<IconUserCheck /> Accepted
		</span>
	),
	[ReservationUserState.Cancelled]: undefined,
	[ReservationUserState.Empty]: undefined,
};
