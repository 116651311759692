import { IconLogoSquare } from '@troon/icons/logo-square';
import { twJoin } from '@troon/tailwind-preset/merge';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	class?: string;
	direction?: 'cw' | 'ccw';
	size?: '32' | '48' | '64';
}>;

export function AccessElement(props: Props) {
	return (
		<div
			class={twJoin(
				'relative overflow-hidden rounded bg-gradient-to-r from-neutral-950 to-brand-700 text-white',
				props.class,
			)}
		>
			<IconLogoSquare
				class={twJoin(
					'absolute -right-12 -top-16 -z-0 origin-center text-brand opacity-20',
					props.direction === 'ccw' ? '-rotate-12 gradient-mask-bl-10' : 'rotate-12 gradient-mask-br-10',
					props.size === '32' && 'size-32 md:size-48',
					props.size === '48' && 'size-48 md:size-64',
					(!props.size || props.size === '64') && 'size-64 md:size-96',
				)}
			/>
			<div class="relative z-0 size-full">{props.children}</div>
		</div>
	);
}
