import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconUserCheck(props: IconProps) {
	return (
		<Icon {...props}>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M15 19c0-2.21-2.686-4-6-4s-6 1.79-6 4m18-9l-4 4l-2-2m-6 0a4 4 0 1 1 0-8a4 4 0 0 1 0 8"
			/>
		</Icon>
	);
}
